import { Dropdown, MenuProps, notification, Select, Tooltip } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../../app/store'
import CLOSE_ICON from '../../../assets/images/close-success.png'
import ERROR from '../../../assets/images/error.png'
import LOGO from '../../../assets/images/logo.png'
import Define from '../../../constants/define'
import { Page, RootPage } from '../../../enum'
import { PagingEnum } from '../../../enum/PagingEnum'
import { ModalName } from '../../../features/stock/modal/customModalSlice'
import { useChangeStockHook } from '../../../hook/changeStockHook'
import { clearClient } from '../../../redux/reducers/accountSlice'
import { renderDigitsByNumber } from '../../../utils'
import './Nav.scss'
import StockNavIcon from '../../../assets/icons/StockNavIcon'
import CommandeNavIcon from '../../../assets/icons/CommandeNavIcon'
import AttenduNavIcon from '../../../assets/icons/AttenduNavIcon'
import HistoryNavIcon from '../../../assets/icons/HistoryNavIcon'
import { logout } from '../../../http/clientAPI'
import packageInfo from '../../../../package.json'

export interface INavBarProps {}

const items: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <Link
        className="flex items-center"
        to={`/gestion-de-stock/stock?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
      >
        <StockNavIcon />
        <div className="ml-2">Stock</div>
      </Link>
    ),
  },
  {
    key: '2',
    label: (
      <Link
        className="flex items-center"
        to={`/gestion-de-stock/attendu?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
      >
        <AttenduNavIcon />
        <div className="ml-2">Attendus</div>
      </Link>
    ),
  },
  {
    key: '3',
    label: (
      <Link
        className="flex items-center"
        to={`/gestion-de-stock/commande?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
      >
        <CommandeNavIcon />
        <div className="ml-2">Commandes</div>
      </Link>
    ),
  },
  {
    key: '4',
    label: (
      <Link
        className="flex items-center"
        to={`/gestion-de-stock/history?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
      >
        <HistoryNavIcon />
        <div className="ml-2">Historique</div>
      </Link>
    ),
  },
]

function currentMenuStock(name: string): string {
  if (!items) return ''
  switch (name) {
    case ModalName.FOURNISSEUR:
    case ModalName.ATTENDU: {
      return items[1]?.key as string
    }
    case ModalName.DESTINATAIRE:
    case ModalName.TRANSPORTEUR:
    case ModalName.COMMANDE: {
      return items[2]?.key as string
    }
    case ModalName.STOCK: {
      return items[0]?.key as string
    }
    case ModalName.HISTORY: {
      return items[3]?.key as string
    }
    default: {
      return ''
    }
  }
}

const itemsConfig: MenuProps['items'] = [
  {
    key: '0',
    label: (
      <Link
        to={`/configurations/client?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
      >
        Clients
      </Link>
    ),
  },
  {
    key: '1',
    label: (
      <Link
        to={`/configurations/societe?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
      >
        Sociétés
      </Link>
    ),
  },
  {
    key: '2',
    label: (
      <Link
        to={`/configurations/utilisateur?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
      >
        Utilisateurs
      </Link>
    ),
  },
  {
    key: '3',
    label: (
      <Link
        to={`/configurations/reference?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
      >
        Références
      </Link>
    ),
  },
  {
    key: '4',
    label: (
      <div
        onClick={() => {
          window.location.href = `${
            process.env.REACT_APP_WAREHOUSE_URL
          }/configuration?client=${localStorage.getItem(
            Define.CHOOSING_CLIENT
          )}&warehouse=${localStorage
            .getItem(Define.CHOOSING_WAREHOUSE)
            ?.replace('ware_', '')}&company=${localStorage.getItem(
            Define.CHOOSING_COMPANY
          )}`
        }}
      >
        Entrepôts
      </div>
    ),
  },
]

function currentMenuConfig(name: string): string {
  if (!itemsConfig) return ''
  switch (name) {
    case Page.CLIENT:
      return itemsConfig[0]?.key as string
    case Page.SOCIETE:
      return itemsConfig[1]?.key as string
    case Page.UTILISATEUR:
      return itemsConfig[2]?.key as string
    case Page.REFERENCE:
      return itemsConfig[3]?.key as string
    default: {
      return ''
    }
  }
}

/**
 * The @NavBar is a component display the navbar of the web. It includes Stock, Inventory, Configuration, Factory.
 * @author [anhnq]
 * @version 0.1
 * @since 16-Mar
 */
export function NavBar(props: INavBarProps) {
  const username = localStorage.getItem(Define.USERNAME)
  const { name } = useSelector((state: RootState) => state.modal)
  const { currentPage, rootPage } = useSelector(
    (state: RootState) => state.page
  )

  const USERNAME_MAX_DIGITS = 20

  const { version } = packageInfo

  const dispatch = useDispatch()
  const handleOnSelect = (value: any) => {
    switch (value.key) {
      case 'Parametres':
        //TODO: Route to profile
        break
      case 'Deconnexion':
        //TODO: Logout the system
        logout().catch((_) => {
          notification.open({
            className: 'noti noti-error',
            message: (
              <div className="flex items-center">
                <img src={ERROR} alt="error" width={50} className="mr-2.5" />
                Une erreur s'est produite. Merci de réessayer ultérieurement
              </div>
            ),
            placement: 'topRight',
            closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
            duration: 3,
          })
        })
        dispatch(clearClient())
        break
      case 'Version':
        break
      default:
        break
    }
  }

  useChangeStockHook()

  return (
    <div className="bg-white">
      <div className="header h-16 flex justify-between bg-primary items-center">
        <span className="flex items-center">
          <img src={LOGO} alt="logo" className="p-5 h-10" />
          <Link
            to={'/tableau-de-bord'}
            className={`text-white mx-5 3xl:mx-10 menu-item no-underline ${
              rootPage === RootPage.TABLEAU_DE_BORD ? 'active' : ''
            } `}
          >
            Tableau de bord
          </Link>
          <Dropdown
            prefixCls="customize-dropdown-antd"
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: [''],
              selectedKeys: [currentMenuStock(name)],
            }}
            dropdownRender={(menu) => {
              return (
                <div className={`bg-cl-primary bd-bot-header-dropdown`}>
                  {menu}
                </div>
              )
            }}
          >
            <p
              className={`text-white mx-5 3xl:mx-10 menu-item no-underline ${
                rootPage === RootPage.GESTION_DE_STOCK ? 'active' : ''
              }`}
            >
              Gestion de Stock
            </p>
          </Dropdown>
          <Link
            to={'/facturation'}
            className={`text-white mx-5 3xl:mx-10 menu-item no-underline ${
              rootPage === RootPage.FACTURATION ? 'active' : ''
            }`}
          >
            Facturation
          </Link>
          <Link
            to={'/inventaire'}
            className={`text-white mx-5 3xl:mx-10 menu-item no-underline ${
              rootPage === RootPage.INVENTAIRE ? 'active' : ''
            }`}
          >
            Inventaire
          </Link>
          <Dropdown
            prefixCls="customize-dropdown-antd"
            menu={{
              items: itemsConfig,
              selectable: true,
              defaultSelectedKeys: [''],
              selectedKeys: [currentMenuConfig(currentPage)],
            }}
            dropdownRender={(menu) => {
              return (
                <div className={`bg-cl-primary bd-bot-header-dropdown`}>
                  {menu}
                </div>
              )
            }}
          >
            <p
              className={`text-white mx-5 3xl:mx-10 menu-item no-underline ${
                rootPage === RootPage.CONFIGURATIONS ? 'active' : ''
              }`}
            >
              Configurations
            </p>
          </Dropdown>
        </span>
        <SelectTooltip
          title={
            username && username?.length > USERNAME_MAX_DIGITS ? username : ''
          }
        >
          <Select
            className="nav-select"
            showArrow={false}
            placeholder={
              <>
                <div className="flex items-center">
                  <span className="bg-white rounded-full p-0.5 mr-2">
                    <i className="icon-avatar"></i>
                  </span>

                  {username && (
                    <>{renderDigitsByNumber(username, USERNAME_MAX_DIGITS)}</>
                  )}
                </div>
              </>
            }
            style={{ width: 230 }}
            bordered={false}
            labelInValue
            dropdownStyle={{
              borderRadius: 0,
            }}
            onSelect={handleOnSelect}
            options={[
              {
                value: 'Parametres',
                label: (
                  <div className="flex items-center border-gray1 border-b border-solid border-0 pb-3">
                    <i className="nav-dropdown icon-config"></i>
                    Paramètres
                  </div>
                ),
              },
              {
                value: 'Deconnexion',
                label: (
                  <div className="flex items-center border-gray1 border-b border-solid border-0 pb-3">
                    <i className="nav-dropdown icon-logout"></i>
                    Déconnexion
                  </div>
                ),
              },
              {
                disabled: true,
                value: 'Version',
                label: (
                  <div className="flex items-center border-b-stone-400">
                    {version}
                  </div>
                ),
              },
            ]}
          />
        </SelectTooltip>
      </div>
    </div>
  )
}

const SelectWrapper = ({ onMouseEnter, onMouseLeave, ...rest }: any) => {
  const ref = useRef()

  useEffect(() => {
    const span = ref.current as any
    span?.addEventListener('mouseenter', onMouseEnter)
    span.addEventListener('mouseleave', onMouseLeave)
    return () => {
      span.removeEventListener('mouseenter', onMouseEnter)
      span.removeEventListener('mouseleave', onMouseLeave)
    }
  }, [onMouseEnter, onMouseLeave])
  return (
    <div
      ref={ref}
      style={{
        display: 'inline-block',
      }}
      {...rest}
    />
  )
}

const SelectTooltip = ({ children, ...rest }: any) => {
  return (
    <Tooltip {...rest}>
      <SelectWrapper>{children}</SelectWrapper>
    </Tooltip>
  )
}
