import * as React from 'react'
import DevelopingImg from '../../assets/images/developing.png'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export interface INotFoundProps {}
/**
 * The @NotFound is a screen showing 404 not found content. So, end user enter some router link but it does not exist on the system. Then, the system will redirect to Notfound Page.
 * @author [anhht]
 * @version 0.1
 */
export function NotFound(props: INotFoundProps) {
  const navigation = useNavigate()
  const [path, setPath] = useState('')

  useEffect(() => {
    setPath(window.location.pathname.replace('/', ''))
  }, [navigation])

  return (
    <div className="w-screen h-[calc(100vh-12rem)]">
      <p className="text-primary text-[24px] font-bold uppercase pl-20 pt-5">
        {path}
      </p>
      <div className="w-full h-full flex items-center justify-center">
        <div className="w-[800px]">
          <p className="text-primary text-right font-bold text-[96px] leading-[117px] m-0">
            {t('developing')}
          </p>
          <p className="text-primary text-right text-[40px] leading-[49px] m-0 mt-4 pl-[204px]">
            {t('comingSoon')}
          </p>
        </div>

        <img src={DevelopingImg} alt={'developing'} width={385} height={385} />
      </div>
    </div>
  )
}
