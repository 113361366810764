export enum RootPage {
  TABLEAU_DE_BORD = 'tableau-de-bord',
  GESTION_DE_STOCK = 'gestion-de-stock',
  FACTURATION = 'facturation',
  INVENTAIRE = 'inventaire',
  CONFIGURATIONS = 'configurations',
  OTHER = 'OTHER',
}
export enum Page {
  TABLEAU_DE_BORD = 'tableau de bord',
  STOCK = 'Stock',
  ATTENDUS = 'Attendus',
  COMMANDES = 'Commandes',
  REFERENCE = 'reference',
  SOCIETE = 'societe',
  CLIENT = 'client',
  UTILISATEUR = 'utilisateur',
  HISTORY = 'history'
}
