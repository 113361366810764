import CacheBuster from 'react-cache-buster'
import { Navigate, Route, Routes } from 'react-router-dom'
import packageInfo from '../package.json'
import './assets/scss/bottom.scss'
import './assets/scss/color.scss'
import './assets/scss/common.scss'
import './assets/scss/customize-antd.scss'
import './assets/scss/heading.scss'
import './assets/scss/icon.scss'
import './assets/scss/noti.scss'
import './assets/scss/position.scss'
import './assets/scss/sizing.scss'
import './assets/scss/spacing.scss'
import './features/stock/modal/type/attendu/Attendu.scss'
import './features/stock/attendu/Attendu.scss'
import './features/stock/modal/CustomModal.scss'
import { NotFound } from './components/Common'
import Loading from './components/Common/Loading/Loading'
import { CommandeType } from './enum/CommandeType'
import { MODE } from './enum/mode'

import usePressTab from './hook/usePressTab'
import { lazy, Suspense, useEffect } from 'react'
import { Spin } from 'antd'
import { NavBar } from './components/Common/Nav/Nav'
import ReactGA from 'react-ga4'

const AuthGuard = lazy(() => import('./guard/AuthGuard'))

const HomeLayout = lazy(() => import('./components/Layout/HomeLayout'))

const CommandeHistory = lazy(
  () => import('./features/history/commande/CommandeHistory')
)
const HistoryGeneral = lazy(
  () => import('./features/history/general/HistoryGeneral')
)
const AttenduHistory = lazy(
  () => import('./features/history/attendu/AttenduHistory')
)
const SsccHistory = lazy(() => import('./features/history/sscc/SsccHistory'))
const StockCreate = lazy(
  () => import('./features/stock/stockscreen/component/modal/StockCreate')
)
const TransporteurView = lazy(
  () => import('./features/stock/modal/type/commonModal/TransporteurViewOnly')
)
const FournisseurView = lazy(
  () => import('./features/stock/modal/type/commonModal/FournisseurViewOnly')
)
const DestinataireView = lazy(
  () => import('./features/stock/modal/type/commonModal/DestinataireViewOnly')
)
const TestPdf = lazy(
  () => import('./features/stock/modal/type/commande/TestPdf')
)
const CommandeMisEnPreparation = lazy(
  () => import('./features/stock/modal/type/commande/CommandeMisEnPreparation')
)
const CommandeEdit = lazy(
  () => import('./features/stock/modal/type/commande/CommandeEdit')
)
const AttenduEdit = lazy(
  () => import('./features/stock/modal/type/attendu/AttenduEdit')
)
const AttenduCreate = lazy(
  () => import('./features/stock/modal/type/attendu/AttenduCreate')
)

const AttenduOnMission = lazy(
  () => import('./features/stock/attendu/AttenduOnMission')
)

const ReferenceHistory = lazy(
  () => import('./features/history/reference/ReferenceHistory')
)
const UtilisateurCreate = lazy(
  () => import('./features/configurations/utilisateur/UtilisateurCreate')
)
const UtilisateurScreen = lazy(
  () => import('./features/configurations/utilisateur/Utilisateur')
)
const Societe = lazy(
  () => import('./features/configurations/societe/societeDetail/Societe')
)
const RefEdit = lazy(
  () => import('./features/configurations/reference/catalogue/RefEdit')
)
const ClientScreen = lazy(
  () => import('./features/configurations/client/ClientScreen')
)

const ClientCreate = lazy(
  () => import('./features/configurations/client/clientDetail/ClientCreate')
)

const CommandeCreate = lazy(
  () => import('./features/stock/modal/type/commande/CommandeCreate')
)

const DashboardComponent = lazy(() => import('./features/dashboard/Dashboard'))

const StockComponent = lazy(
  () => import('./features/stock/stockscreen/NewStock')
)
const AttenduComponent = lazy(() => import('./features/stock/attendu/Attendu'))
const CommandeComponent = lazy(
  () => import('./features/stock/commande/Commande')
)
const ReferenceComponent = lazy(
  () => import('./features/configurations/reference/ReferenceScreen')
)

const SocieteComponent = lazy(
  () => import('./features/configurations/societe/SocieteScreen')
)

const ConditionnementPopup = lazy(
  () =>
    import('./features/stock/stockscreen/component/modal/ConditionnementPopup')
)

const EmplacementPopup = lazy(
  () => import('./features/stock/stockscreen/component/modal/EmplacementPopup')
)

const ClientEdit = lazy(
  () => import('./features/configurations/client/clientDetail/ClientEdit')
)

const ProtectedRoute = ({ children, ...rest }: any) => (
  <HomeLayout {...rest}>{children}</HomeLayout>
)

function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'visit equateur page',
    })
  }, [])

  usePressTab()
  const isProduction = process.env.NODE_ENV.toString() === 'production'
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      reloadOnDowngrade={true}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      onCacheClear={() => console.log('cache clear')}
    >
      <div className="fixed top-0 w-full z-50">
        <NavBar />
      </div>
      <AuthGuard>
        <Suspense
          fallback={
            <div className="w-screen h-screen flex justify-center items-center">
              <Spin size="large" />
            </div>
          }
        >
          <Routes>
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <NotFound />
                </ProtectedRoute>
              }
            />

            {/* dashboard routes */}
            <Route
              path="/"
              element={<Navigate to={'/tableau-de-bord'} replace />}
            ></Route>
            <Route
              path="tableau-de-bord"
              element={
                <ProtectedRoute>
                  <DashboardComponent />
                </ProtectedRoute>
              }
            />

            {/* stock routes */}
            <Route
              path="/gestion-de-stock/stock"
              element={
                <ProtectedRoute>
                  <StockComponent />
                </ProtectedRoute>
              }
            >
              <Route
                path="chrono/reference/:id"
                element={<ReferenceHistory />}
              />
              <Route path="chrono/sscc/:sscc" element={<SsccHistory />} />
              <Route path="stock-create" element={<StockCreate />} />
              <Route
                path="conditionnement"
                element={<ConditionnementPopup />}
              />
              <Route path="emplacement" element={<EmplacementPopup />} />
            </Route>

            {/* attendu routes */}
            <Route
              path="gestion-de-stock/attendu"
              element={
                <ProtectedRoute>
                  <AttenduComponent />
                </ProtectedRoute>
              }
            >
              <Route path="chrono/sscc/:sscc" element={<SsccHistory />} />
              <Route path="attendu-create" element={<AttenduCreate />} />
              <Route
                path="attendu-on-mission/:id"
                element={<AttenduOnMission />}
              />
              <Route path="attendu-edit/:id" element={<AttenduEdit />} />
              <Route
                path="reference/only-view/:id"
                element={<RefEdit mode={MODE.VIEW} />}
              />
              <Route
                path="fournisseur/only-view/:id"
                element={<FournisseurView />}
              />
              <Route path="chrono/:id" element={<AttenduHistory />} />
            </Route>

            {/* commande routes */}
            <Route
              path="gestion-de-stock/commande"
              element={
                <ProtectedRoute>
                  <CommandeComponent />
                </ProtectedRoute>
              }
            >
              <Route path="chrono/sscc/:sscc" element={<SsccHistory />} />
              <Route path="commande-create" element={<CommandeCreate />} />
              <Route path="commande-edit/:id" element={<CommandeEdit />} />
              <Route
                path="commande-preparation/:id"
                element={
                  <CommandeMisEnPreparation
                    commandeType={CommandeType.Mis_en_preparation}
                  />
                }
              />
              <Route
                path="commande-envoye-en-mission/:id"
                element={
                  <CommandeMisEnPreparation
                    commandeType={CommandeType.Envoye_en_mission}
                  />
                }
              />
              <Route
                path="commande-envoyer-en-chargement/:id"
                element={
                  <CommandeMisEnPreparation
                    commandeType={CommandeType.Envoyer_en_chargement}
                  />
                }
              />
              <Route
                path="destinataire/only-view/:id"
                element={<DestinataireView />}
              />
              <Route
                path="transporteur/only-view/:id"
                element={<TransporteurView />}
              />
              <Route path="chrono/:id" element={<CommandeHistory />} />
            </Route>

            <Route
              path="gestion-de-stock/history"
              element={
                <ProtectedRoute>
                  <HistoryGeneral />
                </ProtectedRoute>
              }
            ></Route>

            {/* configurations routes */}
            <Route
              path="/configurations/reference"
              element={
                <ProtectedRoute>
                  <ReferenceComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/configurations/reference/:id"
              element={
                <ProtectedRoute>
                  <RefEdit mode={MODE.EDIT} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/configurations/reference/ref-create"
              element={
                <ProtectedRoute>
                  <RefEdit mode={MODE.CREATE} />
                </ProtectedRoute>
              }
            />

            {/* societe route */}
            <Route
              path="/configurations/societe"
              element={
                <ProtectedRoute>
                  <SocieteComponent />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/configurations/societe/societe-create"
              element={
                <ProtectedRoute>
                  <Societe mode={MODE.CREATE} />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              path="/configurations/societe/societe-edit"
              element={
                <ProtectedRoute>
                  <Societe mode={MODE.EDIT} />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              path="/configurations/client"
              element={
                <ProtectedRoute>
                  <ClientScreen />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/configurations/client/client-create"
              element={
                <ProtectedRoute>
                  <ClientCreate />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              path="/configurations/utilisateur"
              element={
                <ProtectedRoute>
                  <UtilisateurScreen />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/configurations/utilisateur/utilisateur-create"
              element={
                <ProtectedRoute>
                  <UtilisateurCreate />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              path="/configurations/client/:id"
              element={
                <ProtectedRoute>
                  <ClientEdit />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              path="/test/file/123"
              element={
                <ProtectedRoute>
                  <TestPdf />
                </ProtectedRoute>
              }
            ></Route>
          </Routes>
        </Suspense>
      </AuthGuard>
    </CacheBuster>
  )
}

export default App
